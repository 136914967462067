


































  import { Vue, Component, Prop } from 'nuxt-property-decorator'

  @Component({
    components: {
      OneFooterListItem: () => import('~/components/molecules/items/OneFooterListItem.vue'),
    },
  })
  export default class OneCmsGridCollapse extends Vue {
    @Prop({
      type: String,
      required: false,
    })
    readonly title!: string;

    @Prop({
      type: String,
      required: false,
    })
    readonly html!: string;
  }
